<template>
  <div>
    <el-card shadow="never">
      <h2>Отправить email</h2>
      <el-form>
        <el-form-item prop="mailAddressTo">
          <el-input v-model="emailModel.mailAddressTo" placeholder="Email адрес"></el-input>
        </el-form-item>
        <el-form-item prop="subject">
          <el-input v-model="emailModel.subject" placeholder="Тема"></el-input>
        </el-form-item>
        <el-form-item prop="body">
          <el-input v-model="emailModel.body" placeholder="Текст письма"></el-input>
        </el-form-item>
        <el-form-item>
          <div>
            <el-button :loading="loading"
                       class="login-button mt-4"
                       @click="sendEmail"
                       block>Отправить</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import emailApi from '@/api/email';

export default {
  name: 'EmailSender',
  data() {
    return {
      emailModel: {
        mailAddressTo: '',
        subject: '',
        body: '',
      },
      loading: false,
    };
  },
  methods: {
    async sendEmail() {
      if (!this.emailModel.mailAddressTo || !this.emailModel.subject || !this.emailModel.body) return;

      this.loading = true;
      const res = await emailApi.sendEmail(this.emailModel);

      if (res && res.data) {
        this.$alert(res.data.resultMessage, 'Результат отправки email', {
          confirmButtonText: 'OK',
          type: 'warning',
        });
      }

      this.loading = false;
    }
  }
};
</script>

<style scoped>

</style>
